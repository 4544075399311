import { Image } from "@chakra-ui/react";
import s from "./bulletSection.module.scss"

type Props = {
    title: string,
    iconSrc: string,
    paragraphs:  Map<string, string>
}

export default function BulletSection(props: Props) {
    return <div className={s.bulletSection}>
        <Image src={props.iconSrc} alt="" />
        <div>
            <h4>{props.title}</h4>
            {
                Array.from(props.paragraphs.keys()).map((key) => {
                    return <>
                        <h6>{key}</h6>
                        <p>{props.paragraphs.get(key)}</p>
                    </>
                })
            }
        </div>
    </div>
}