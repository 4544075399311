"use client";

import { laptopProductLaunchpad, teaserConfigure, teaserDesign, teaserExploreSandbox, teaserOneAccount, teaserLaunch, teaserProductReview } from 'public/assets'
import { Image } from "@chakra-ui/react";
import s from './page.module.scss'
import classNames from 'classnames';
import BulletSection from './components/bulletSection';
import Carousel from './components/carousel';
import { ROUTE_BASE_URL } from '@/route-config/route-path';

const designMap = new Map([
    ["Comprehensive Product Integration", "Seamlessly combine savings, loans, investments, and more products into customizable bundles suited for different customer segments."],
    ["Flexible Configuration", "Utilize the platform to create tailored  solutions that cater to the specific needs of your commercial and retail customers, enhancing their banking experience."]
]);

const configureMap = new Map([
    ["Custom Rules Application", "Implement rules for product interaction, eligibility criteria, and benefits allocation to design competitive offerings."],
    ["Dynamic Feature Addition", "Enrich your bundles with unique features such as automated rebalancing, loyalty points, or preferential rates to distinguish your product in the market."]
]);

const launchMap = new Map([
    ["Effortless Deployment", "Utilize our intuitive platform for a smooth launch of your bundled products, ensuring they reach your target audience effectively."],
    ["Real-Time Adjustments", "Monitor performance, gain insights, and add customer focused adjustments to your offerings in real time for optimal results."]
]);

export default function teaser() {
    return <div className={s.teaser}>
        <div className={s.headline}>
            <div className={classNames(s.headlineContent, s.contentCenter)}>
                <h2>Enhance your business, expand market share, and offer exclusive customer benefits with Multi-Position Accounts</h2>
                <p>Streamline complexity into opportunity &mdash; Enroll to access Finxact resources or log in to your Finxact account to control your fintech roadmap, redefining standards for innovative financial products and experiences.</p>
                <div className={classNames(s.buttonContainer, s.center)}>
                    <a href={ROUTE_BASE_URL.PROFILE_SETUP}><input type='button' className='app-btn-solid-white cp' value='Enroll now' /></a>
                    <a href={ROUTE_BASE_URL.DASHBOARD_PATH}><input type='button' className='app-btn-inverse-secondary cp' value='Log in' /></a>
                </div>
            </div>
        </div>
        <div className={s.hero}>
            <div className={classNames(s.contentCenter)}>
                <Image src={laptopProductLaunchpad.src} alt="laptop" />
            </div>
        </div>
        <div className={s.howItWorks}>
            <div className={classNames(s.howItWorksContent)}>
                <div className={classNames(s.content, s.howItWorksImage)}><Image className={s.productReviewImage} src={teaserProductReview.src} alt="product review" /></div>
                <div className={s.content}>
                    <h2>How It Works: Customize & Accelerate</h2>
                    <BulletSection iconSrc={teaserDesign.src} paragraphs={designMap} title="Design" />
                    <BulletSection iconSrc={teaserConfigure.src} paragraphs={configureMap} title="Configure" />
                    <BulletSection iconSrc={teaserLaunch.src} paragraphs={launchMap} title="Launch" />
                </div>
            </div>
        </div>
        <Carousel />
        <div className={s.exploreSandbox}>
            <div className={classNames(s.content, s.exploreSandboxContent)}>
                <div>
                    <p>EXPLORE MORE WITH FINXACT</p>
                    <h2>The Power of the Finxact Sandbox for Next-Gen Banking</h2>
                    <p>Dive into the Finxact Sandbox, discover the keys to rapid product development and seamless integration. Take the next step to propel your digital banking ambitions into reality, enabling the creation of market-ready, customer-centric financial products with unparalleled speed and flexibility.</p>
                    <div className={s.buttonContainer}>
                        <a href={ROUTE_BASE_URL.PROFILE_SETUP}><input className='app-btn-link cp' type="button" value="Request a Sandbox today &gt;" /></a>
                    </div>
                </div>
                <Image src={teaserExploreSandbox.src} alt="" />
            </div>
        </div>
        <div className={s.discover}>
            {/*
            TODO CNSL-5666
            <h3>Discover lorem ipsum dolor</h3>
            <p>Lorem ipsum dolor sit amet</p>
            */}

            <div className={s.discoverButtons}>
                {/* TODO CNSL-5666 <input className={s.light} type="button" value="Lorem ipsum" /> */}
                <a href={ROUTE_BASE_URL.PROFILE_SETUP}><input type="button" value="Get in touch with Finxact" className='app-btn-inverse-secondary' /></a>
            </div>
        </div>
        <div className={s.footer}>
            Copyright © 2024 Finxact a Fiserv Company. All rights reserved.
        </div>
    </div>
}